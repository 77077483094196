import {
  ChatbotConfiguration,
  DevicesModel
} from "../models/ChatbotConfiguration";
import { WIDGET_POSITIONS_OPTIONS } from "../models/Enum";

export enum deviceSize {
  mobileS = 320,
  mobileM = 375,
  mobileL = 425,
  tablet = 768,
  laptop = 1024,
  laptopS = 1080,
  laptopM = 1370,
  laptopL = 1440,
  desktop = 2560,
}

export class Utils {
  public static formatString(string: string, replacements: string[]): string {
    return string.replace(/{(\d+)}/g, (match: any, position: number) => {
      return typeof replacements[position] !== "undefined"
        ? replacements[position]
        : match;
    });
  }
  public static objectHasValue(
    searchTerm: string | RegExp,
    objectToSearch: any
  ): boolean {
    for (const key in objectToSearch) {
      if (
        objectToSearch.hasOwnProperty(key) &&
        objectToSearch[key] !== undefined
      ) {
        if (typeof objectToSearch[key] === "string") {
          return objectToSearch[key].search(searchTerm) >= 0;
        }
        if (typeof objectToSearch[key] === "number") {
          return (objectToSearch[key] + "").search(searchTerm) >= 0;
        }
        if (typeof objectToSearch[key] === "object") {
          return this.objectHasValue(objectToSearch[key], searchTerm);
        }
      }
    }
    return false;
  }

  // public static compareObjects(obj1, obj2): boolean {
  //     if (obj1 != null &&  typeof(obj) === "object") {
  //     }
  // }

  public static objectIsEqual(obj1: any, obj2: any): boolean {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    if (keys1.length !== keys2.length) {
      return false;
    }
    for (const key of keys1) {
      if (!obj2.hasOwnProperty(key)) {
        return false;
      }
      if (!Utils.deepCompare(obj1[key], obj2[key])) {
        return false;
      }
    }
    return true;
  }

  public static deepCompare(
    obj1: null,
    obj2: string | number | boolean | null
  ): boolean {
    if (obj1 !== null && obj2 !== null && typeof obj1 === typeof obj2) {
      if (typeof obj1 === "string") {
        return obj1 === obj2;
      }
      if (typeof obj1 === "number") {
        return obj1 === obj2;
      }
      if (typeof obj1 === "boolean") {
        return obj1 === obj2;
      }
      if (typeof obj1 === "object") {
        return Utils.objectIsEqual(obj1, obj2);
      }
    }
    return false;
  }

  public static createObjectForEdit(data: any, keysToDelete?: Array<string>) {
    const ins = Object.assign({}, data);
    delete ins["id"];
    delete ins["created_time"];
    if (keysToDelete) {
      for (const key of keysToDelete) {
        delete ins[key];
      }
    }
    return ins;
  }

  public static convertObjectKeysIntoIds(data: any): any {
    const objectKeys = Object.keys(data);
    const returnObject = Object.assign({}, data);
    for (const objKey of objectKeys) {
      const property = data[objKey];
      if (!property) {
        returnObject[objKey] = property;
      } else if (property instanceof Array) {
        const newArray = [];
        for (const item of property) {
          newArray.push(item.id);
        }
        returnObject[objKey] = newArray;
      } else if (typeof property === "object") {
        returnObject[objKey] = property.id;
      } else {
        returnObject[objKey] = property;
      }
    }
    return returnObject;
  }

  public static mergeObjects(target: any, ...objectsToMerge: any[]) {
    const extended: any = {};

    // Loop through our object
    for (const obj of objectsToMerge) {
      for (const prop in obj) {
        if (obj.hasOwnProperty(prop)) {
          // Push each value from `obj` into `extended`
          extended[prop] = obj[prop];
        }
      }
    }
    return extended;
  }
}

export const BlacklistUrlsFn = (
  configData: ChatbotConfiguration | null,
  IFRAME: HTMLIFrameElement,
  location: string,
  screenWidth: number
) => {
  let blacklistedUrls: string[] | null = configData?.urls?.blacklisted_urls;
  //make a temporary array to convert it into url string array
  let abc: any = [];
  blacklistedUrls &&
    blacklistedUrls?.length > 0 &&
    blacklistedUrls?.map((curItem: any, index: number) => {
      let singleUrl: any = new URL(curItem, "https://example.com")?.href;
      // console.log("aaa",new URL(curItem, "https://example.com"))
      abc.push(singleUrl);
    });

  let currentParentWindowUrl: any = new URL(location, "https://example.com")?.href;
  // console.log("url array abc",abc,currentParentWindowUrl,abc.includes(currentParentWindowUrl))
  if (abc.includes(currentParentWindowUrl)) {
    EnabledDevicesFn(configData, IFRAME, screenWidth, true);
  } else {
    EnabledDevicesFn(configData, IFRAME, screenWidth, false);
  }
};

export const EnabledDevicesFn = (
  configData: ChatbotConfiguration | null,
  IFRAME: HTMLIFrameElement,
  screenWidth: number,
  isUrlAlreadyBlacklisted: boolean
) => {
  let enabledDevices: DevicesModel | null = configData?.enabled_devices;
  if (!configData?.enabled_devices) {
    IFRAME.style.display = `inline`;
    return;
  }

  if (isUrlAlreadyBlacklisted) {
    IFRAME.style.display = `none`;
  } else {
    if (deviceSize.tablet < screenWidth && !enabledDevices?.desktop) {
      IFRAME.style.display = `none`;
    } else if (deviceSize.mobileL >= screenWidth && !enabledDevices?.mobile) {
      IFRAME.style.display = `none`;
    } else if (
      deviceSize.mobileL < screenWidth &&
      deviceSize.tablet >= screenWidth &&
      !enabledDevices?.tablet
    ) {
      IFRAME.style.display = `none`;
    } else {
      IFRAME.style.display = `inline`;
    }
    
  }
};

export const WidgetPositionFn = (
  configData: ChatbotConfiguration | null,
  IFRAME: HTMLIFrameElement
) => {
  let widgetDisplayData: string | null = configData?.display_position;
  if (!configData?.display_position) {
    IFRAME.style.right = "8px";
    return;
  }

  if (widgetDisplayData === WIDGET_POSITIONS_OPTIONS.LEFT) {
    IFRAME.style.left = "8px ";
  } else {
    IFRAME.style.right = "8px ";
  }
};
