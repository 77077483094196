import { GetAChatBotFrame } from "./chat-iframe";
import { PreviewType } from "./chat-iframe/models";
import { TrafficTracker } from "./matomo-tracking";

class GetA {
  traffic_tracker: TrafficTracker;
  chatbot_frame: GetAChatBotFrame;

  constructor(
    geta_host: string,
    site_id: string,
    bot_id: string,
    is_widget_preview: string | null,
    location: string,
    geta_id: string,
    isFullScreenBot: boolean,
  ) {
    if (is_widget_preview !== PreviewType.getaWidgetPreview) {
      this.traffic_tracker = new TrafficTracker(site_id);
    }
    this.chatbot_frame = new GetAChatBotFrame(
      geta_host,
      bot_id,
      is_widget_preview,
      location,
      geta_id,
      isFullScreenBot,
    );
  }
}

declare global {
  interface Window {
    // Visit: any;
    GetA: any;
  }
}

(function (window) {
  window.GetA = (
    geta_host,
    site_id,
    bot_id,
    is_widget_preview,
    windowLocation,
    geta_id,
    isFullScreenBot,
  ) => {
    return new GetA(
      geta_host,
      site_id,
      bot_id,
      is_widget_preview,
      window.location.href,
      geta_id,
      isFullScreenBot,
    );
  };
})(window);
