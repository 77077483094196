import { BREAKPOINTS_DEVICE_SIZE } from "./utils/contants";

export class WidgetStylingUtils {
  iframe_element: HTMLIFrameElement | any;
  widget_config: any;
  currentDevice: "desktop" | "mobile" = "desktop";
  currentPosition: "Left" | "Right" = "Right";

  constructor(widget_config: any, iframe_element: HTMLIFrameElement) {
    this.iframe_element = iframe_element;
    this.widget_config = widget_config;
    this.currentDevice =
      window.innerWidth < BREAKPOINTS_DEVICE_SIZE.mobileL
        ? "mobile"
        : "desktop";
    this.currentPosition =
      (this.currentDevice === "mobile"
        ? widget_config?.mobile_display_position
        : widget_config?.desktop_display_position) ?? "Right";
  }

  getWidgetIntialStyling() {
    this.getDisplayStatus();
    this.getIntialHeightWidth();
    this.getIframeBottomMargin();
    this.getIframeSidePosition();
  }

  getDisplayStatus() {
    const device =
      window.innerWidth < BREAKPOINTS_DEVICE_SIZE.tablet &&
      window.innerWidth >= BREAKPOINTS_DEVICE_SIZE.mobileL
      ? "tablet"
      : window.innerWidth < BREAKPOINTS_DEVICE_SIZE.mobileL
      ? "mobile"
      : "desktop";
      let visibility = this.widget_config?.enabled_devices;

    if (visibility?.[device] === false) {
      this.iframe_element.style.border = "1px solid red";
      this.iframe_element.style.display = "none";
    }

    let urls = this.widget_config?.urls?.blacklisted_urls ?? [];

    let currentUrl = window.location.href;

    urls?.length > 0 &&
      urls?.forEach((url: string) => {
        if (currentUrl?.includes(url)) {
          this.iframe_element.style.display = "none";
        }
      });
  }

  updateIframeDimensions(
    deviceSize: { width: string; height: string },
    status: boolean = false,
    device: "mobile" | "desktop" = this.currentDevice
  ) {
    this.iframe_element.style.width = deviceSize?.width;
    this.iframe_element.style.height = deviceSize?.height;
  }

  centerIframePosition() {
    this.iframe_element.style.left = 0;
    this.iframe_element.style.right = 0;
    this.iframe_element.style.margin = "0 auto"; /* Center horizontally */
  }

  // margin Bottom
  getIframeBottomMargin(curDevice: "mobile" | "desktop" = this.currentDevice) {
    if (curDevice === "mobile") {
      this.iframe_element.style.bottom =
        (this.widget_config?.mobile_bottom_margin ?? 10) + "px";
    } else {
      this.iframe_element.style.bottom =
        (this.widget_config?.desktop_bottom_margin ?? 10) + "px";
    }
  }

  getIframeSidePosition(pos: "Left" | "Right" = this.currentPosition) {
    if (pos === "Left") {
      this.getIframeLeftMargin();
    }
    if (pos === "Right") {
      this.getIframeRightMargin();
    }
  }

  // margin left
  getIframeLeftMargin(curDevice: "mobile" | "desktop" = this.currentDevice) {
    if (curDevice === "mobile") {
      this.iframe_element.style.right = "auto";
      this.iframe_element.style.left =
        (this.widget_config?.mobile_side_margin ?? 10) + "px";
    } else {
      this.iframe_element.style.right = "auto";
      this.iframe_element.style.left =
        (this.widget_config?.desktop_side_margin ?? 10) + "px";
    }
  }
  // margin right
  getIframeRightMargin(curDevice: "mobile" | "desktop" = this.currentDevice) {
    if (curDevice === "mobile") {
      this.iframe_element.style.left = "auto";
      this.iframe_element.style.right =
        (this.widget_config?.mobile_side_margin ?? 10) + "px";
    } else {
      this.iframe_element.style.left = "auto";
      this.iframe_element.style.right =
        (this.widget_config?.desktop_side_margin ?? 10) + "px";
    }
  }
  getIntialHeightWidth() {
    this.iframe_element.style.height = "80px";
    this.iframe_element.style.width = "80px";
  }

  getWidgetPositionConfig = () => {
    const config = this.widget_config;
    const device =
      window.innerWidth < BREAKPOINTS_DEVICE_SIZE.mobileL
        ? "mobile"
        : "desktop";
    const position =
      (device === "mobile"
        ? config?.mobile_display_position
        : config?.desktop_display_position) ?? "Right";
    const bottomMargin =
      ((device === "mobile"
        ? config?.mobile_bottom_margin
        : config?.desktop_bottom_margin) ?? 10) + "px";
    const sideMargin =
      ((device === "mobile"
        ? config?.mobile_side_margin
        : config?.desktop_side_margin) ?? 10) + "px";
    return { device, position, bottomMargin, sideMargin };
  };

  getWidgetPositionMobileOpened = () => {
    this.iframe_element.style.bottom = `8px`;
    this.iframe_element.style.left = `8px`;
    this.iframe_element.style.right = `8px`;
  };

  getWidgetPositionMobileClosed = () => {
    this.getIframeBottomMargin();
    this.getIframeSidePosition();
  };
}
