import { Observable, Subject, ReplaySubject } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { MATOMO_BASE_URL } from '../environments';

declare const window: any;
export class TrafficTracker {
    static current_instance: TrafficTracker;
    static ready_notifier: ReplaySubject<TrafficTracker> = new ReplaySubject();


    static getCurrentInstance() {
        return TrafficTracker.current_instance;
    }

    static getReadyNotifier(): Observable<TrafficTracker> {
        return TrafficTracker.ready_notifier.asObservable().pipe(startWith(TrafficTracker.getCurrentInstance()));
    }

    site_id: string;
    _paq: any;
    visitor_id: string | null;
    

    constructor(site_id) {
        TrafficTracker.current_instance = this;

        this.site_id = site_id;
        var _paq = window._paq = window._paq || [];
        this._paq = _paq;
        /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
        _paq.push(['trackPageView']);
        _paq.push(['enableLinkTracking']);
        

        var u=MATOMO_BASE_URL;
        _paq.push(['setTrackerUrl', u+'matomo.php']);
        _paq.push(['setSiteId', this.site_id]);
        var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
        g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
        g.onload = (ev) => {
            TrafficTracker.ready_notifier.next(TrafficTracker.getCurrentInstance());
            this._paq = _paq;
        } 

        
    }

    push(...args: any[]) {
        // console.log(args);
        // console.log(...args);
        // debugger;
        window._paq.push(...args);
    }

    getVisitorId(): Promise<string> {
        const _this = this;
        const p = new Promise<string>((resolve, reject) => {
            this._paq.push([ function() { 
                _this.visitor_id = this.getVisitorId(); 
                resolve(_this.visitor_id);
            }])
        });
        return p;
    }
    


}
