import { Observable, ReplaySubject } from "rxjs";
import { CHATBOT_BASE_URL } from "../environments";
import { ChatbotConfiguration } from "../models/ChatbotConfiguration";
import { AppConfig } from "../utils/app-config";
import { TrafficTracker } from "./../matomo-tracking/index";
import { IFrameWidgetCommService } from "./IframeWidgetAppService";
import {
  CommMessage,
  IEvents,
  MatomoEvent,
  OEvents,
  PreviewType,
  TrackingEventTypes,
} from "./models";
import { WidgetStylingUtils } from "./WidgetStylingUtils";
import {
  getChatbotParamsData,
} from "../utils/chatbot-helpers";

declare const window: any;
declare const gtag: any;
const MOBILE_BREAKPOINT = 768;

const MUTATION_CONFIG = { subtree: true, childList: true };

export class GetAChatBotFrame {
  iframe_element: HTMLIFrameElement;
  bot_id: string;
  isWidgetPreview: string | null;
  iframe_comm_service: IFrameWidgetCommService;
  tracker_ready_obs: Observable<any>;
  tracker_instance: TrafficTracker;
  iframe_ready_notifier: ReplaySubject<any>;
  visitor_id: string;
  location: string;
  widgetUtils: any;
  widgetPositionConfig: {
    device: "mobile" | "desktop";
    position: "Left" | "Right";
    width: string;
    height: string;
  };
  viewportSize: {
    width: any;
    height: any;
  };
  geta_id: any;
  isFullScreenBot: boolean;

  constructor(
    geta_host,
    bot_id,
    is_widget_preview,
    location,
    geta_id,
    isFullScreenBot
  ) {
    this.bot_id = bot_id;
    this.isWidgetPreview = is_widget_preview;
    this.iframe_comm_service = new IFrameWidgetCommService();
    this.iframe_ready_notifier = new ReplaySubject();
    this.tracker_ready_obs = TrafficTracker.getReadyNotifier();
    const iframe_obs = this.iframe_ready_notifier.asObservable();
    this.geta_id = geta_id;
    this.isFullScreenBot = isFullScreenBot;

    this.viewportSize = {
      width: window.innerWidth,
      height: window.innerHeight,
    };

    this.handleResize = this.handleResize.bind(this);
    // * Intial trigger Event
    iframe_obs.subscribe((result) => {
      setTimeout(() => {
        const session_data = AppConfig.getUserData("chatbot_session_data");
        const keep_chat_open = AppConfig.getUserData("redirect_keep_chat_open");
        const livechat_session_data = AppConfig.getUserData(
          "livechat_session_change"
        );
        const chatbot_session_id = AppConfig.getUserData("chatbot_session_id");
        const chatbot_params = getChatbotParamsData();

        this.iframe_comm_service.postMessage(
          new CommMessage(OEvents.CHATBOT_START, {
            bot_id: this.bot_id,
            keep_chat_open: keep_chat_open,
            geta_host: geta_host,
            device: {
              width: window.innerWidth,
              height: window.innerHeight,
            },
            session_data: session_data,
            livechat_session_data: livechat_session_data,
            is_widget_preview: is_widget_preview,
            chatbot_session_id: chatbot_session_id,
            location: location,
            isFullScreenBot: this.isFullScreenBot,
            chatbot_params: chatbot_params
          })
        );
        if (keep_chat_open) {
          AppConfig.deleteUserData("redirect_keep_chat_open");
        }
        if (this.visitor_id) {
          this.iframe_comm_service.postMessage(
            new CommMessage(OEvents.CHATBOT_SAVE_VISITOR_ID, {
              visitor_id: this.visitor_id,
            })
          );
        }
      }, 100);
    });

    // * Save Visitor Event
    this.tracker_ready_obs.subscribe((result) => {
      if (this.isWidgetPreview !== PreviewType.getaWidgetPreview) {
        this.tracker_instance = result;
        this.tracker_instance.getVisitorId().then((visitor_id) => {
          iframe_obs.subscribe((result) => {
            this.visitor_id = visitor_id;
            this.iframe_comm_service.postMessage(
              new CommMessage(OEvents.CHATBOT_SAVE_VISITOR_ID, {
                visitor_id: visitor_id,
              })
            );
          });
        });
      }
    });

    if (document.readyState !== "loading") {
      this.initIFrame();
    } else {
      document.addEventListener("DOMContentLoaded", () => {
        this.initIFrame();
      });
    }

    // * Chatbot Resize window Event
    this.iframe_comm_service.registerEventListener(
      IEvents.CHATBOT_RESIZE,
      (event: any) => {
        const data = event.data;
        const widgetState: "open" | "close" = data.data.state;
        const isMobile = this.widgetPositionConfig.device === "mobile";

        this.iframe_element.style.minHeight = `auto`;
        this.iframe_element.style.minWidth = `auto`;

        if (this.geta_id) {
          this.iframe_element.style.width = `100%`;
          this.iframe_element.style.height = `100%`;
        } else {
          const mobileHeight = this.viewportSize.height ?this.viewportSize.height - 16 : 610;
          const desktopHeight = data.data?.height ?? 610;
          const maxHeight = isMobile ? mobileHeight : desktopHeight;
          const height = isMobile ? mobileHeight : desktopHeight;
          this.iframe_element.style.width = data.data.width + `px`;
          this.iframe_element.style.maxHeight = `${maxHeight}px`;
          this.iframe_element.style.height = height + `px`;
        }

        if (isMobile) {
          if (widgetState === "open") {
            this.widgetUtils.getWidgetPositionMobileOpened();
          } else {
            this.widgetUtils.getWidgetPositionMobileClosed();
          }
        }

        // if (widgetState === "open" && isMobile) {
        //   this.iframe_element.style.bottom = `8px`;
        //   this.iframe_element.style.left = `8px`;
        //   this.iframe_element.style.right = `8px`;
        // }
        // else {
        //   this.widgetUtils.getWidgetIntialStyling();
        // }
      }
    );

    // * Chatbot Open Trigger Matomo Event
    this.iframe_comm_service.registerEventListener(
      IEvents.CHATBOT_OPEN,
      (event: any) => {
        if (this.isWidgetPreview !== PreviewType.getaWidgetPreview) {
          const data = event.data;
          this.tracker_instance.push([
            "trackEvent",
            MatomoEvent.CATEGORY,
            MatomoEvent.CHAT_OPEN_ACTION,
            MatomoEvent.CHAT_OPEN_NAME,
            1,
          ]);
        }
      }
    );

    // * Keep Chat Open if Redirection with Flow Event
    this.iframe_comm_service.registerEventListener(
      IEvents.CHATBOT_HOST_URL_REDIRECT,
      (event: any) => {
        const data = event.data;
        if (data?.data?.url) {
          AppConfig.setUserData("redirect_keep_chat_open", true);
          window.location.assign(data.data.url);
        }
      }
    );

    // * Chatbot Session Change Event
    this.iframe_comm_service.registerEventListener(
      IEvents.CHATBOT_SESSION_CHANGE,
      (event: any) => {
        const data = event.data;
        AppConfig.setUserData("chatbot_session_data", data.data);
      }
    );

    // * Chatbot Session ID Event
    this.iframe_comm_service.registerEventListener(
      IEvents.CHATBOT_SESSION_ID,
      (event: any) => {
        const data = event.data;
        AppConfig.setUserData("chatbot_session_id", data.data);
      }
    );

    // * Livechat Session Change Event
    this.iframe_comm_service.registerEventListener(
      IEvents.LIVECHAT_SESSION_CHANGE,
      (event: any) => {
        const data = event?.data;
        AppConfig.setUserData("livechat_session_change", data.data);
      }
    );

    // * GTM Tracking Event
    this.iframe_comm_service.registerEventListener(
      IEvents.TRACKING_EVENTS,
      (event: any) => {
        const data = event.data?.data;
        if (
          data &&
          data.length > 0 &&
          this.isWidgetPreview !== PreviewType.getaWidgetPreview
        ) {
          data
            // .filter((d:any) => d?.type == "tracking")
            .forEach((d: any) => {
              switch (d?.name) {
                case TrackingEventTypes.GTM_CONVERSION:
                  window.dataLayer?.push(d?.data);
                  return;
                case TrackingEventTypes.MATOMO_CONVERSION:
                  this.tracker_instance?.push([
                    "trackEvent",
                    MatomoEvent.CATEGORY,
                    MatomoEvent.CHAT_CONVERSION_ACTION,
                    MatomoEvent.CHAT_CONVERSION_NAME,
                    1,
                  ]);
                  return;
                case TrackingEventTypes.MATOMO_SET_USER_ID:
                  this.tracker_instance?.push(d?.data);
                  return;
              }
            });
        }
      }
    );

    // * ChatbotConfiguration Event
    this.iframe_comm_service.registerEventListener(
      IEvents.CHATBOT_CONFIGURATION,
      (event: any) => {
        const configData: ChatbotConfiguration | null = event?.data?.data;

        //* no config data found
        if (!configData) {
          return;
        }
        this.widgetUtils = new WidgetStylingUtils(
          configData,
          this.iframe_element
        );
        this.widgetUtils.getWidgetIntialStyling();

        this.widgetPositionConfig = this.widgetUtils.getWidgetPositionConfig();
        // if (
        //   !configData ||
        //   this.isWidgetPreview === PreviewType.getaWidgetPreview
        // ) {
        //   this.iframe_element.style.display = "inline";
        //   this.iframe_element.style.right = "0px ";
        //   return;
        // }

        // WidgetPositionFn(configData, this.iframe_element);

        // BlacklistUrlsFn(
        //   configData,
        //   this.iframe_element,
        //   window.location.href,
        //   window?.innerWidth
        // );

        // let previousUrl = "";

        // const observer = new MutationObserver((entries: any) => {
        //   if (window.location.href !== previousUrl) {
        //     // do your thing
        //     // console.log(`URL changed from ${previousUrl} to ${window.location.href}`);
        //     previousUrl = window.location.href;
        //     BlacklistUrlsFn(
        //       configData,
        //       this.iframe_element,
        //       window.location.href,
        //       window?.innerWidth
        //     );
        //   }
        // });
        // start observing change
        // observer.observe(document, MUTATION_CONFIG);
      }
    );
  }

  componentDidMountResize() {
    // Attach the resize event listener when the component mounts
    window.addEventListener("resize", this.handleResize);
  }

  handleResize() {
    this.viewportSize = {
      width: window.innerWidth,
      height: window.innerHeight,
    };
    if (this.iframe_comm_service?.postMessage) {
      this.iframe_comm_service?.postMessage(
        new CommMessage(OEvents.PARENT_WINDOW_RESIZE, {
          viewportSize: this.viewportSize,
        })
      );
    }
  }

  /* actual chatbot iframe */
  initIFrame() {
    // var u = `${CHATBOT_BASE_URL}/`;
    // var d = document;
    // var g = d.createElement("iframe");
    // g.setAttribute("class", "geta-iframe-wrapper");
    // this.iframe_element = g;
    // this.iframe_comm_service.setIFrameElement(this.iframe_element);
    // g.setAttribute("id", "gct_" + this.bot_id);
    // g.src = u;

    // g.title =
    //   this.isWidgetPreview === PreviewType.getaWidgetPreview
    //     ? PreviewType.getaWidgetPreview
    //     : "Geta Chatbot widget";
    // g.name =
    //   this.isWidgetPreview === PreviewType.getaWidgetPreview
    //     ? PreviewType.getaWidgetPreview
    //     : "Geta Chatbot widget";

    // g.allow = "microphone";
    // g.allowFullscreen = true;

    // g.style.zIndex = "999999999999999999";
    // g.style.display = "inline";
    // g.style.bottom = "0px";
    // g.style.right = "0px";
    // g.style.marginLeft = "0px";
    // g.style.marginRight = "0px";
    // g.style.width = "80px";
    // g.style.height = "96px";
    // g.style.overflow = "hidden";
    // g.style.border = "none";
    // // document.body.appendChild(g);

    // // Check if this.my_var has a value and use getElementById
    // if (this.geta_id) {
    //   var targetElement = document.getElementById(this.geta_id);
    //   if (targetElement) {
    //     g.style.position = "unset";
    //     targetElement.appendChild(g);
    //   } else {
    //     console.error("Target element not found with id:", this.geta_id);
    //   }
    // } else {
    //   g.style.position = "fixed";
    //   document.body.appendChild(g);
    // }

    // this.iframe_element.onload = () => {
    //   this.iframe_ready_notifier.next(true);
    //   if (this.isWidgetPreview !== PreviewType.getaWidgetPreview) {
    //     this.componentDidMountResize();
    //   }
    // };

    //new code
    const url = `${CHATBOT_BASE_URL}/` || "http://localhost:8081";

    const id = "geta_" + this.bot_id;

    // Create the iframe element
    const iframe = document.createElement("iframe");
    // Store the iframe element in your class property
    this.iframe_element = iframe;
    // // Set the iframe element to the comm service
    this.iframe_comm_service.setIFrameElement(this.iframe_element);

    iframe.setAttribute("class", "geta-iframe-wrapper");
    iframe.setAttribute("id", "gct_" + this.bot_id);
    iframe.allow = "microphone";
    iframe.allowFullscreen = true;

    iframe.src = url;
    iframe.title = id;
    iframe.name = id;
    iframe.id = id;
    iframe.allowFullscreen = true;
    iframe.style.position = "fixed";
    iframe.style.zIndex = "9999999999999";
    iframe.style.display = "inline";
    iframe.style.overflow = "hidden";
    iframe.style.border = "none";
    iframe.style.height = "1px";
    iframe.style.width = "1px";
    iframe.style.minHeight = "1px";
    iframe.style.minWidth = "1px";
    iframe.style.borderRadius = "2px";
    iframe.style.transition = "all 0.12s ease-in-out";

    // Append the iframe to the document body
    // document.body.appendChild(iframe);

    // // Check if this.my_var has a value and use getElementById
    if (this.geta_id) {
      var targetElement = document.getElementById(this.geta_id);
      if (targetElement) {
        iframe.style.position = "unset";
        targetElement.appendChild(iframe);
      } else {
        console.error("Target element not found with id:", this.geta_id);
      }
    } else {
      iframe.style.position = "fixed";
      document.body.appendChild(iframe);
    }

    this.iframe_element.onload = () => {
      this.iframe_ready_notifier.next(true);
    };

    // this.iframe_element.onload = () => {
    //   this.iframe_ready_notifier.next(true);
    //   if (this.isWidgetPreview !== PreviewType.getaWidgetPreview) {
    //     this.componentDidMountResize();
    //   }
    // };
  }
}
