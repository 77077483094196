import { LocalStorage } from "./LocalStorage";
import moment from "moment";

declare var environments: any;
declare var process: any;

export const ADMIN_KEY = "loggedInPortalAdminData";
export const USER_KEY = "loggedInUserData";
export const SESSION_KEY = "SavedSessionData";
const CONFIG_PARAMS: any = {
  // 'API_BASE_URL': environment.API_BASE_PATH,
};

const localStorage = new LocalStorage();

export class AppConfig {
  private static config = CONFIG_PARAMS;

  public static getConfig(param_name: string): any {
    if (this.config.hasOwnProperty(param_name)) {
      return this.config[param_name];
    }
    return false;
  }

  public static getCSRFToken(): string {
    return this.getCookie("csrftoken");
  }
  private static getCookie(name: string) {
    const value = "; " + document.cookie;
    const parts: any = value.split("; " + name + "=");
    if (parts.length === 2) {
      return parts.pop().split(";").shift();
    }
    return null;
  }

  private static isExpired(timestamp: string): boolean {
      // const twoMinutesAgo = moment().subtract(2, "minutes");
      // return moment(timestamp).isBefore(twoMinutesAgo);
    const twoDaysAgo = moment().subtract(2, "days");
    return moment(timestamp).isBefore(twoDaysAgo);
  }

  public static setUserData(key: string, value: any) {
    const stringValue = JSON.stringify({
      data: value,
      timestamp: moment().toISOString(),
    });
    localStorage.set(key, stringValue);
  }

  public static getUserData(key: string) {
    const stringValue = localStorage.get(key);
    if (stringValue && typeof stringValue === "string") {
      const { data, timestamp } = JSON.parse(stringValue);
      if (!this.isExpired(timestamp)) {
        return data;
      } else {
        localStorage.remove(key);
      }
    }
    return null;
  }

  public static deleteUserData(key: string) {
    localStorage.remove(key);
  }
}
