import { CommMessage, INCOMING_EVENTS, OEvents, OUTGOING_EVENTS } from "./models";


export class IFrameWidgetCommService {
    iframe_element: HTMLIFrameElement;
    ievent_fn_map: Map<string, (event: any) => void>;
    initMessageListeners: () => void;

    constructor(iframe?: HTMLIFrameElement) {
        this.iframe_element = iframe;
        this.ievent_fn_map = new Map();
        this.initMessageListeners = () => {
            window.addEventListener("message", (event) => {
                if (event.data.hasOwnProperty('event_type')) {
                    const event_name = event.data.event_type;
                    if(INCOMING_EVENTS.has(event_name)) {
                        if (this.ievent_fn_map.has(event_name)) {
                            this.ievent_fn_map.get(event_name)(event);
                        }
                    }
                }
            }, false);
        }
        this.initMessageListeners();
    }
    setIFrameElement(iframe: HTMLIFrameElement) {
        this.iframe_element = iframe;
    }
    registerEventListener(event_name, event_callback) {
        if(INCOMING_EVENTS.has(event_name)) {
            this.ievent_fn_map.set(event_name, event_callback);
        } else {
            throw new Error('IEvent Type not available!')
        }
    }

    postMessage(message: CommMessage) {
        if (this.iframe_element && this.iframe_element.contentWindow) {
            if (OUTGOING_EVENTS.has(message.event_type as OEvents)) {
                this.iframe_element.contentWindow.postMessage(message, '*');
            } else {
                throw new Error("OEvent Type not available!")
            }
        } else {
            throw new Error("Iframe not available!")
        }
    }

}